
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.wrapper-content {
  padding: 20px 10px 40px;
}

.page-heading {
  border-top: 0;
  padding: 0 10px 20px 10px;
}

.white-bg {
  background-color: #ffffff;
}

.ibox {
  clear: both;
  margin-top: 0;
  padding: 0;
}

.ibox-title {
  background-color: #ffffff;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 3px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 14px 15px 7px;
  min-height: 48px;

  h5 {
    display: inline-block;
    font-size: 14px;
    margin: 0 0 7px;
    padding: 0;
    text-overflow: ellipsis;
    float: left;
  }
}

.ibox-tools {
  display: block;
  float: none;
  margin-top: 0;
  position: relative;
  padding: 0;
  text-align: right;
}

.ibox-content {
  background-color: #ffffff;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
  clear: both;
}

.panel-heading {
  font-weight: bold;
}

.preview_image {
  max-width: 250px;
  max-height: 250px;
}

#tabs {
  font-size: 12px;
}

.btn_group {
  margin-top: 10px;
  float: right;
}

//Huisstijl PVUitheem

.h3, h3 {
  font-size: 30px;
  text-transform: uppercase;
}

body, .body {
  background: #faf5d7;
  padding-top: 20px;
}
@media only screen and (max-width : 320px){
  body, .body {
    padding-top: 10px;
  }
  .container{
    padding: 2px;
  }
}

.navbar-brand {
  padding: 0px;
  margin-left: 15px;
}

.navbar-brand > img {
  height: 50px;
  padding: 5px;
  width: auto;
}

.navbar {
  box-shadow: 0 0 20px black;
  background-color: #b42828;
  border: 2px solid #faf5d7;
}

.maintitle{
  color: #b42828;
  font-weight: bold;
}
.title{
  color: #b42828;
  a {
    color: #b42828;
  }
}

.navbar-default .navbar-nav > li > a {
  color: #ffffff;
  padding-top: 20px;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
  color: #faf5d7;
  box-shadow: 0px 1px 10px #AA0000;
}

.header-bg {
  max-width: 100%;
}
.headerimg{
  padding: 0px;
  display: block;
}

ul.hoofdmenu {
  list-style-type: none;
  height: 40px;
  width: 100%;
  background-color: #b42828;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  margin-bottom: 40px;
}

ul.hoofdmenu li {
  float: left;
  height: 40px;
  line-height: 40px;
  display: block;
}

ul.hoofdmenu > li > a {
  font-family: 'Roboto Condensed', sans-serif;
  display: block;
  padding-right: 10px;
  padding-left: 10px;
  color: #fffcf3;
  margin-top: 5px;
  text-decoration: none;
  font-size: 18px;
}

ul.hoofdmenu > li.active > a {
  background-color: #fffcf3;
  color: #b42828;
  margin-top: 5px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

ul.hoofdmenu li ul {
  display: none;
}

ul.hoofdmenu > li.active ul {
  display: block;
  position: absolute;
  left: 0;
  top: 40px;
  width: 100%;
  height: 40px;
  background-color: #fffcf3;
  border-bottom: 1px solid #b42828;
  list-style-type: disc;
  list-style-position: inside;
  color: #b42828;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

ul.hoofdmenu li.active ul li > a {
  color: #b42828;
  padding: 0;
  margin: 0;
  text-decoration: none;
}

ul.hoofdmenu li.active ul li {
  margin: 0px 10px;
}

ul.hoofdmenu li.active ul li:first-of-type {
  margin-left: 20px;
}

ul.hoofdmenu > li > a:hover{
  background-color: #fffcf3;
  color: #b42828;
  margin-top: 5px;
  border: solid 1px #b42828;
  padding-right: 9px;
  padding-left: 9px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

ul.hoofdmenu > li.active ul li > a:hover{
  background-color: #b42828;
  color: #fffcf3;

  border: solid 1px #b42828;
  padding: 10px
}

ul.hoofdmenu .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  background-color: #fff;
  color: #B42828;
}

.footer {
  background-color: #b42828;
  color: #ffffff;
}

.navbar-default .navbar-toggle {
  border-color: #faf5d7;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #AA0000;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #FFF;
}

.agenda {
  position: absolute;
  top: 25%;
  right: 55px;
  width: 400px;
  box-shadow: 0px 0px  35px #AA0000;
  background: rgba(255,255,255,.8);
  color: #AA0000 !important;
  z-index: 10;

  li:before{
    z-index: 999;
    content: "\e080";
    font-family: 'Glyphicons Halflings';
    font-size: 9px;
    float: left;
    margin-top: 4px;
    margin-left: -17px;
    color: blue;
  }
}

.agendaheader {
  color: #AA0000 !important;
  font-size: 16px;
  font-weight: bold;
}

.agendali{
  color: #AA0000 !important;

}

.pandelshadow{
  box-shadow: 0 0 30px black;
  border: solid 2px #faf5d7;
}
.glyphicon{
  padding-right: 5px;
}

.content {
  ul li {
    padding-left: 35px;
    background: url('/images/bullet-list.gif') no-repeat 22px 4px;
    line-height: 170%;
    list-style: none;
  }
}

.spelregel-lijst li {
  list-style-type: decimal;
}

@media only screen and (max-width : 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #fff;
  }
}